<template>
  <div class="container">
    <div class="row d-flex" v-if="insertable">
      <div class="col">
        <el-form ref="reportform" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="updating">
          <div class="row pt-0">
            <div class="col-md-4 pr-md-1">
            <label class="form-label">统计月份</label>
            <el-form-item prop="month" :rules="[{required:true}]">
                <el-date-picker v-model="model.month" type="month" class="fw" placeholder="选择月份"></el-date-picker>
            </el-form-item>
            </div>
            <div class="col-md-3 pr-md-1 pl-md-1">
            <label class="form-label">总销售金额</label>
            <el-form-item prop="totalAmount" :rules="[{required:true}]">
                <el-input type="number" v-model="model.totalAmount" placeholder="万元,保留两位小数"></el-input>
            </el-form-item>
            </div>
            <div class="col-md-3 pr-md-1 pl-md-1">
            <label class="form-label">扶贫商品总额</label>
            <el-form-item prop="fupinAmount" :rules="[{required:true}]">
                <el-input type="number" v-model="model.fupinAmount" placeholder="万元,保留两位小数"></el-input>
            </el-form-item>
            </div>
            <div class="col-md-2 pl-md-1">
              <label class="form-label text-white">-</label>
              <el-form-item prop="fupinAmount" :rules="[{required:true}]">
                  <el-button type="success" class="inline-button ls-1" @click="submit">插入</el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <el-table refs="reportTable" row-key="id" header-row-class-name="thead-light" :height="autoHeight? 300 : 400" :class="tableClass" style="width: 100%" v-loading="loading"
          :data="tableData" stripe @expand-change="expandChange">
          <el-table-column type="expand" width="30">
            <template slot-scope="{row}">
              <div class="row d-flex px-md-3">
                <div class="col-md-9">
                  <h6>修改历史</h6>
                  <report-histories v-if="row.expanded" :key="'history-counter-report-'+row.id" 
                    :auto-height="true" table-class="bg-white" :reportid="row.id" type="counter">
                  </report-histories>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="month" label="月份" class-name="pl-2" width="100">
            <template slot-scope="{row}">
                {{ row.year }}-{{ formatInt2(row.month,2) }}
            </template>
          </el-table-column>
          <el-table-column prop="total_amount" label="总额(万元)" width="110">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.total_amount ? parseFloat(row.total_amount).toFixed(2) : '0.00' }}</span>
                <el-input size="mini" type="number" placeholder="0.00"
                  v-if="editable" v-show="row.editing" v-model="row.newVals.total_amount">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="fupin_amount" label="扶贫额(万元)" width="120">
            <template slot-scope="{row}">
                <span v-show="!row.editing">{{ row.fupin_amount ? parseFloat(row.fupin_amount).toFixed(2) : '0.00' }}</span>
                <el-input size="mini" type="number" placeholder="0.00"
                  v-if="editable" v-show="row.editing" v-model="row.newVals.fupin_amount">
                </el-input>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="填报时间" min-width="145">
              <template slot-scope="scope">
                  {{ formatDateTime(scope.row.created_at) }}
              </template>
          </el-table-column>
          <el-table-column prop="updated_at" label="修改时间" min-width="145" v-if="editable">
              <template slot-scope="scope">
                  {{ formatDateTime(scope.row.updated_at) }}
              </template>
          </el-table-column>
          <el-table-column width="100px" label="#" class-name="px-2 text-center" label-class-name="action-col-header" v-if="editable">
              <template slot-scope="{row}">
                  <a href="javascript:;" @click="edit(row)" v-show="!row.editing" class="border-bottom mx-1"><i class="el-icon-edit-outline"></i> 修改</a>
                  <a href="javascript:;" @click="cancelEdit(row)" v-show="row.editing" :class="row.updating?'disabled':''" class="text-danger">[取消]</a>
                  <a href="javascript:;" @click="update(row)" v-show="row.editing" :class="row.updating?'disabled':''" class="ml-2">[{{ row.updating ? "···" : "保存" }}]</a>
              </template>
          </el-table-column>
          <!-- <template slot="append">
            <p v-infinite-scroll="fetchData" v-if="loading">Loading...</p>
          </template> -->
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */ 
//import moment from "moment";
//import { Form, FormItem } from 'element-ui';

export default {
  components: {
    // [Form.name]: Form,
    // [FormItem.name]: FormItem,
    'report-histories': () => import('./ReportEditHistories')
  },
  props: {
    counterid: {
        type: Number,
        description: "current object id",
        default: null
    },
    insertable: {
        type: Boolean,
        description: "Can insert data",
        default: true
    },
    editable: {
        type: Boolean,
        description: "Can update data",
        default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    tableClass: {
      type: String,
      default: ''
    }
  },
  watch: {
    counterid(v) {
      if(v) this.fetchData();
      else this.tableData = []
    }
  },
  data() {
    return {
      loading: false,
      updating: false,
      model: {
        counterid: this.counterid,
        month: new Date(),
        totalAmount: '',
        fupinAmount: ''
      },
      tableData: [],
      filters: { limit: 10, page: 1, counterid: this.counterid }
    }
  },
  methods: {
    formatDateTime(datestr) {
      if(!datestr) return "";
      return moment(datestr).format("YYYY-MM-DD HH:mm")
    },
    formatInt2(num, length) {
        return num<10 ? (Array(length).join('0') + num).slice(-length) : num;
    },
    fetchData() {
      this.loading = true;
      this.axios.get("counterreports", { params: { ...this.filters } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data.map((x)=>{
              return { updating: false, editing: false, expanded: false, ...x, newVals: { ...x } }
            });
            this.filters.page = res.data.data.page;
        }
        else {
            this.$notifyErr('未能加载专柜历史数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    submit() {
      this.$refs['reportform'].validate((valid) => {
          if (valid) {
              this.save();
          } else {
              return false;
          }
      });
    },
    save() {
      this.updating = true;
      this.axios.post('counterreports', this.model).then((res) => {
          this.updating = false;
          if (res.data.errcode == 0) {
              this.fetchData();
              this.$refs['reportform'].resetFields();
          }
          else {
              this.$notifyErr('保存数据失败', res.data.errmsg);
          }
      }).catch((err) => {
          if(err.response) {
              if(err.response.data.error) this.$notifyErr('保存数据失败', err.response.data.error.message);
              else this.$notifyErr('保存数据失败', err.response.data[0].message);
          }
          else this.$notifyErr('保存数据失败', '错误信息: ' + err.message);
          this.updating = false;
      })
    },
    edit(row) {
      row.editing = true
    },
    cancelEdit(row) {
      row.editing = false
    },
    update(row) {
      row.updating = true;
      this.axios.put('counterreports/'+row.id, row.newVals).then((res) => {
          row.updating = false;
          if (res.data.errcode == 0) {
              Object.assign(row, res.data.data)
              Object.assign(row.newVals, res.data.data)
              row.editing = false;
          }
          else {
              this.$notifyErr('保存数据失败', res.data.errmsg);
          }
      }).catch((err) => {
          this.$notifyErr('保存数据失败', '错误信息: ' + err.message);
          this.updating = false;
      })
    },
    expandChange(row) {
      row.expanded = !row.expanded
    }
  },
  mounted() {
    this.fetchData()
  }
};
</script>
<style>
.card .table td, .card .table th, .card .el-table td, .card .el-table th {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.fw.el-date-editor.el-input {
  width:100%;
}
.el-button.inline-button {
  width:100%;
}
</style>